@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #28253c;
  --secondary: #a996ff;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 1px solid var(--primary);
}

.column * {
  scrollbar-color: #262338 #0e0e1c;
}

.listbox {
  width: 100%;
  max-height: 16rem;
  overflow-y: auto;
  scrollbar-width: thin;
  scroll-padding-top: 1.5rem;
  scroll-padding-bottom: 1.5rem;
}
