@tailwind base;

@layer base {
  [type="text"],
  input:where(:not([type])),
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply p-0;
  }
}
@tailwind components;
@tailwind utilities;

html,
body {
  @apply bg-offblack;
}

input,
input:focus {
  @apply border-none focus:ring-0 focus:ring-offset-0;
}

textarea,
textarea:focus {
  @apply border-none focus:ring-0 focus:ring-offset-0;
}
